import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "sonner"
import { useUser } from "@supabase/auth-helpers-react"
import { useDocumentTitle } from "@/src/hooks/UseDocumentTitle.ts"
import { ActionContainer } from "./Constants"
import { ArrowLeft, ArrowRight } from "lucide-react"
import Template from "@/src/components/Template.tsx"
import { useFromApplication } from "@/src/hooks/UseFromApplication.ts"

const AgreementPage = () => {
  const fromApplication = useFromApplication()
  useDocumentTitle(["Agreement"])
  const navigate = useNavigate()
  const user = useUser()
  const urlApplicationId = useParams().application_id

  const [userEnteredEmail, setUserEnteredEmail] = useState("")

  async function handleUpdateApplication() {
    if (userEnteredEmail !== user?.email) {
      toast.error("Please enter your email to continue.")
      return
    }
    const { error } = await fromApplication.update({ signed_agreements_on: new Date().toISOString() })
    if (error) {
      toast.error("Could not update agreements")
      return
    }

    navigate(`/apply/${urlApplicationId}/summary`)
  }

  const [buttonStates, setButtonStates] = useState<Map<number, boolean>>(() => new Map())

  return (
    <>
      <Template
        slug="apply/_/agreements#1"
        markdownComponents={{
          li: (props) => {
            const identifier = props.node?.position?.start.offset
            if (identifier === undefined) {
              throw Error("Could not bind agreement buttons")
            }
            if (buttonStates.get(identifier) == undefined) {
              setButtonStates(
                (prevState) => new Map([...prevState.entries(), [identifier, !!prevState.get(identifier)]]),
              )
            }

            return (
              <li {...{ ...props, children: undefined }}>
                <div className="flex items-center gap-4 py-2">
                  <Button
                    className="flex-shrink-0"
                    variant={buttonStates.get(identifier) ? "success" : "secondary"}
                    onClick={() => {
                      setButtonStates(
                        (prevState) => new Map([...prevState.entries(), [identifier, !prevState.get(identifier)]]),
                      )
                    }}
                  >
                    I/We agree!
                  </Button>
                  {props.children}
                </div>
              </li>
            )
          },
        }}
      />

      <div className="max-w-4xl mx-auto flex flex-col items-center gap-4">
        <div className="w-full">
          <label htmlFor="description" className="font-semibold">
            Enter your email {user?.email} to continue:
          </label>

          <Input
            placeholder={user?.email}
            value={userEnteredEmail}
            onChange={(e) => setUserEnteredEmail(e.target.value)}
            disabled={![...buttonStates.values()].every((id) => id)}
          />
        </div>
      </div>
      <div className="w-full">
        <ActionContainer>
          <Button
            onClick={() => {
              navigate(`/apply/${urlApplicationId}/details`)
            }}
            size="lg"
          >
            <ArrowLeft className="w-5 h-5" />
            Previous Page
          </Button>
          <Button
            onClick={handleUpdateApplication}
            size="lg"
            disabled={
              ![...buttonStates.values()].every((id) => id) || !userEnteredEmail || userEnteredEmail !== user?.email
            }
          >
            Continue
            <ArrowRight className="w-5 h-5" />
          </Button>
        </ActionContainer>
      </div>
    </>
  )
}

export default AgreementPage
