import { Button } from "@/components/ui/button"
import { toast } from "sonner"
import { useFromApplication } from "@/src/hooks/UseFromApplication.ts"
import { useNavigate } from "react-router-dom"
import { useDocumentTitle } from "@/src/hooks/UseDocumentTitle.ts"
import { QueryData } from "@supabase/supabase-js"
import { useEffect, useState } from "react"
import { DataTable } from "@/src/components/DataTable.tsx"
import { DefaultColumns } from "@/src/pages/apply/Applicants.tsx"
import { Database, Tables } from "@/lib/database.types.ts"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import Template from "@/src/components/Template.tsx"

const QUERY_STRING =
  "*, submitted_at, applicant ( *, instrument ( description ) ), application_availability (preference_order, group_cast ( * ))"
const NOT_EXECUTED = () => useFromApplication().select(QUERY_STRING).single()

type FullApplication = QueryData<ReturnType<typeof NOT_EXECUTED>>

function parseApplication(application: FullApplication) {
  const {
    submitted_at,
    applicant: applicants,
    notification_cellphones,
    previous_application,
    previous_participation,
    application_availability,
    signed_agreements_on,
  } = application
  let { address1, address2, city, state, zip, country, description } = application

  let applicationIsComplete = true

  let phoneNumber = "Missing"
  let previousApplicationString = ""
  let previousParticipationString = ""

  if (previous_participation && previous_participation.length > 0) {
    previousParticipationString = previous_participation.join(", ")
  }
  if (previous_application && previous_application.length > 0) {
    previousApplicationString = previous_application.join(", ")
  }
  if (
    notification_cellphones == null ||
    notification_cellphones.length == 0 ||
    notification_cellphones[0].length == 0
  ) {
    applicationIsComplete = false
  } else {
    phoneNumber = notification_cellphones[0]
  }

  ;[address1, city, state, zip, description] = [address1, city, state, zip, description].map((requiredStringField) => {
    if (requiredStringField == null || requiredStringField.length == 0) {
      applicationIsComplete = false
      return "Missing"
    }
    return requiredStringField
  })
  ;[address2, country] = [address2, country].map((optionalStringField) => {
    if (optionalStringField == null || optionalStringField.length == 0) {
      return "None"
    }
    return optionalStringField
  })

  for (const applicant of applicants) {
    const { given_name, family_name, birth_date, female, family_role /* instrument */ } = applicant
    if (female == null) {
      applicationIsComplete = false
    }
    for (const requiredStringField of [given_name, family_name, birth_date, family_role]) {
      if (requiredStringField == null || requiredStringField.length == 0) {
        applicationIsComplete = false
      }
    }
    // there is no need for validation on instruments
  }

  if (application_availability.length < 1) {
    applicationIsComplete = false
  }
  application_availability.sort((a, b) => a.preference_order - b.preference_order)

  if (signed_agreements_on == null) {
    applicationIsComplete = false
  }

  return {
    submitted_at,
    applicant: applicants,
    address1,
    address2,
    city,
    state,
    zip,
    country,
    phoneNumber,
    previousApplicationString,
    previousParticipationString,
    description,
    application_availability,
    applicationIsComplete,
    signed_agreements_on,
  }
}

function ApplicantsTable({ persons }: { persons: Tables<"applicant">[] }) {
  return (
    <div className="pb-2 pt-2">
      <DataTable columns={[...DefaultColumns]} data={persons} hideOtherActions hidePagination />
    </div>
  )
}

function Photo({ applicationId }: { applicationId: string }) {
  const supabase = useSupabaseClient()
  const [fileUrl, setFileUrl] = useState("")
  useEffect(() => {
    if (applicationId)
      supabase.storage
        .from("family_photo")
        .createSignedUrl(`${applicationId}.jpg`, 60 * 60)
        .then(({ data }) => {
          if (data) {
            setFileUrl(data.signedUrl)
          }
        })
  }, [applicationId, supabase.storage])
  return <img className="max-w-lg" src={fileUrl} alt="Family Photo" />
}

const SummaryPage = () => {
  useDocumentTitle(["Summary"])
  const navigate = useNavigate()
  const fromApplication = useFromApplication()
  const supabaseClient = useSupabaseClient<Database>()

  const [application, setApplication] = useState<FullApplication | null>()

  useEffect(() => {
    async function iife() {
      const { data } = await fromApplication.select(QUERY_STRING).single()
      setApplication(data)
    }

    iife()
  }, [fromApplication, setApplication])

  async function handleUpdateApplication() {
    const { error: submitError } = await fromApplication.update({
      submitted_at: new Date().toISOString(),
    })

    if (submitError) {
      console.error(submitError)
      toast.error(submitError.message)
      return
    }
    toast.success("Application submitted successfully!")

    const {
      error: emailError,
      data: emailData,
      status: emailStatus,
    } = await supabaseClient.rpc("send_submission_email")
    if (emailError) {
      console.error("Error sending email", emailError)
      toast.error(emailError.message)
      return
    } else if (emailData.length != 1) {
      toast.error("Error sending email")
    }
    console.log({ emailData, emailError, emailStatus })
    if (emailStatus != 200) {
      toast.error("Error sending email")
      console.error("Sendgrid Errors", emailError)
    } else {
      toast.success("Summary email sent!")
      navigate(`/`)
    }
  }
  async function handleWithdrawApplication() {
    const { error: submitError } = await fromApplication.update({
      submitted_at: null,
    })

    if (submitError) {
      console.error(submitError)
      toast.error(submitError.message)
      return
    }
    toast.success("Application withdrawn successfully!")
    const { data } = await fromApplication.select(QUERY_STRING).single()
    setApplication(data)
  }

  const parsedApplication = application ? parseApplication(application) : null

  return (
    <>
      <div className="space-y-4">
        <Template slug="apply/_/summary#1" />
        {application && parsedApplication && (
          <div className={"grid divide-y-[1px] space-y-1"}>
            <div>
              <h2>Applicants</h2>
              <ApplicantsTable persons={parsedApplication.applicant} />
            </div>
            <div>
              <h2>History of Pageant Participation</h2>
              <ul>
                <li>
                  <b>Years Applied:</b> {parsedApplication.previousApplicationString}
                </li>
                <li>
                  <b>Years Participated:</b> {parsedApplication.previousParticipationString}
                </li>
              </ul>
            </div>
            <div>
              <h2>Contact Information</h2>
              <ul>
                <li>
                  <b>Address 1:</b> {parsedApplication.address1}
                </li>
                <li>
                  <b>Address 2:</b> {parsedApplication.address2}
                </li>
                <li>
                  <b>Phone Number:</b> {parsedApplication.phoneNumber}
                </li>
                <li>
                  <b>City:</b> {parsedApplication.city}
                </li>
                <li>
                  <b>State/Province:</b> {parsedApplication.state}
                </li>
                <li>
                  <b>Zip/Postal:</b> {parsedApplication.zip}
                </li>
                <li>
                  <b>Country:</b> {parsedApplication.country}
                </li>
              </ul>
            </div>
            <div>
              <h2>Family Photo</h2>
              <Photo applicationId={application.id} />
            </div>
            <div>
              <h2>Cast Preferences</h2>
              {parsedApplication.application_availability.length === 0 ? (
                "Missing"
              ) : (
                <ol className="list-decimal pl-5">
                  {parsedApplication.application_availability.map((group) => (
                    <li>
                      {group.group_cast?.description} {group.group_cast?.details}
                    </li>
                  ))}
                </ol>
              )}
            </div>

            <div>
              <h2>Bio and Additional Details</h2>
              {parsedApplication.description}
            </div>

            <div>
              <h2>Instruments</h2>
              <ul className="list-disc ml-4">
                {parsedApplication.applicant.map((applicant) => (
                  <li>
                    {applicant.given_name} {applicant.family_name}:{" "}
                    {applicant.instrument.map((i) => i.description).join(", ")}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h2>Agreements</h2>
              {parsedApplication.signed_agreements_on != null
                ? `Signed on ${new Date(parsedApplication.signed_agreements_on).toLocaleString()}`
                : "Not yet signed"}
            </div>
          </div>
        )}
      </div>
      {parsedApplication?.submitted_at && (
        <Button
          className="w-fit"
          disabled={!parsedApplication?.applicationIsComplete}
          size="lg"
          onClick={handleWithdrawApplication}
        >
          Withdraw Application
        </Button>
      )}
      {!parsedApplication?.submitted_at && (
        <Button
          className="w-fit"
          disabled={!parsedApplication?.applicationIsComplete}
          size="lg"
          onClick={handleUpdateApplication}
        >
          Submit Application
        </Button>
      )}
    </>
  )
}

export default SummaryPage
