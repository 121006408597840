import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react"
import { ChevronDown, LogOut } from "lucide-react"
import { Link } from "react-router-dom"

const Header = ({ showFullLogo = false }) => {
  const user = useUser()
  const supabase = useSupabaseClient()

  return (
    <header className="h-16 p-4 flex items-center justify-between gap-4 border-b border-primary-500">
      <Link to="/" className="flex items-center gap-1">
        <img className="w-auto h-16" src="/logo.png" alt="Nauvoo" />
        {showFullLogo && (
          <div className="hidden sm:block leading-none">
            <h4 className="leading-none">Nauvoo Pageant</h4>
            <span className="leading-none tracking-tighter text-sm">2025</span>
          </div>
        )}
      </Link>

      <div>
        {user ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="bg-white border rounded-sm">
                <span className="w-40 truncate">{user?.email}</span>

                <ChevronDown className="w-5 h-5 ml-2" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => supabase.auth.signOut()}>
                <LogOut className="mr-2 h-4 w-4" />
                <span>Log out</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          <div className="hidden sm:block leading-none">
            <h4 className="leading-none">Nauvoo Pagaent</h4>
            <span className="leading-none tracking-tighter text-sm">2025</span>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
