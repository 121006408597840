import { create } from "zustand"

type Store = {
  application_id: string | null
  isLeadership: boolean
  canSubmitAfterDeadline: boolean
}

export const useAppStore = create<Store>(() => ({
  application_id: null,
  isLeadership: false,
  canSubmitAfterDeadline: false,
}))
