import { Button } from "@/components/ui/button"
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { Form } from "@/components/ui/form"
import { InputFormField } from "@/components/ui/input/input-form-field"
import { Database, Tables } from "@/lib/database.types"
import { DataTable } from "@/src/components/DataTable"
import { useDialogHandler } from "@/src/hooks/use-dialog-handler"
import { zodResolver } from "@hookform/resolvers/zod"
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react"
import { ColumnDef } from "@tanstack/react-table"
import { Loader2, MoreVertical, Pencil, Plus, Trash } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "sonner"
import { z } from "zod"
import { useDocumentTitle } from "@/src/hooks/UseDocumentTitle.ts"
import { QueryData } from "@supabase/supabase-js"
import { useAppStore } from "@/src/store"

const QUERY_STRING = `id, user_id, application_id, comment, created_at, modified_at`
const NOT_EXECUTED = () => useSupabaseClient<Database>().from("application_comment").select(QUERY_STRING).single()
type ApplicationComment = QueryData<ReturnType<typeof NOT_EXECUTED>>

const addApplicationCommentFormSchema = z.object({
  id: z.string().optional(),
  comment: z.string().min(1, "Comment text is required"),
})

const formatDateString = (dateStr: string): string => {
  return (
    new Date(dateStr).toLocaleDateString() +
    " " +
    new Date(dateStr).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  )
}

const DefaultColumns: readonly ColumnDef<Tables<"application_comment">>[] = [
  {
    accessorKey: "comment",
    header: "Note",
    cell: ({ row }) => <span className="font-bold">{row.original.comment}</span>,
  },
  {
    accessorKey: "user_email",
    header: "Author Email",
    cell: ({ row }) => <span className="font-bold">{row.original.user_email}</span>,
  },
  {
    accessorKey: "created_at",
    header: "Created",
    cell: ({ row }) => <span className="font-bold">{formatDateString(row.original.created_at)}</span>,
  },
  {
    accessorKey: "modified_at",
    header: "Last Updated",
    cell: ({ row }) => <span className="font-bold">{formatDateString(row.original.modified_at)}</span>,
  },
]

const AdminCommentsPage = () => {
  useDocumentTitle(["Admin Notes"])
  const supabase = useSupabaseClient<Database>()
  const applicationId = useParams().application_id
  const user = useUser()
  const navigate = useNavigate()
  const isLeadership = useAppStore((s) => s.isLeadership)

  const { isDialogOpen, onOpenChange, onOpen, onClose } = useDialogHandler()
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdateForm, setIsUpdateForm] = useState(false)
  const [comments, setComments] = useState<ApplicationComment[]>([])

  const addCommentForm = useForm<z.infer<typeof addApplicationCommentFormSchema>>({
    resolver: zodResolver(addApplicationCommentFormSchema),
    defaultValues: { comment: "", id: undefined },
  })

  useEffect(() => {
    async function fetchAdminComments() {
      const { error, data } = await supabase
        .from("application_comment")
        .select(`*`)
        .eq("application_id", applicationId!)
      if (error) {
        console.error("Error fetching admin comments", error)
      }
      if (data) {
        setComments(data)
      }
      setIsLoading(false)
    }

    if (isLeadership) {
      fetchAdminComments()
    } else {
      navigate("/")
    }
  }, [supabase, applicationId])

  const resetForm = () => {
    addCommentForm.reset({ comment: "", id: undefined })
  }

  useEffect(() => {
    if (!isDialogOpen && isUpdateForm) {
      resetForm()
      setIsUpdateForm(false)
    }
  }, [addCommentForm, isDialogOpen, isUpdateForm])

  const handleDeleteComment = useCallback(
    async (id: string) => {
      const { error } = await supabase.from("application_comment").delete().eq("id", id)

      if (error) {
        toast.error(error.message)
        return
      }

      setComments((prev) => prev.filter((comment) => comment.id !== id))
    },
    [supabase],
  )

  const handleAddComment = useCallback(
    async (values: z.infer<typeof addApplicationCommentFormSchema>) => {
      if (isUpdateForm) {
        if (values.id) {
          const { data } = await supabase
            .from("application_comment")
            .update({
              comment: values.comment,
              user_email: user!.email,
              user_id: user!.id,
            })
            .eq("id", values.id)
            .select("*")
            .single()

          if (data) setComments((prev) => prev.map((comment) => (data.id === comment.id ? data : comment)))
        }
      } else {
        if (applicationId == null) {
          // should be unreachable. Here to satisfy type checker
          toast.error("Could not add note")
          return
        }

        const { data, error } = await supabase
          .from("application_comment")
          .insert({
            user_id: user!.id,
            user_email: user!.email!,
            application_id: applicationId,
            comment: values.comment,
          })
          .select("*")
          .maybeSingle()

        if (error) {
          toast.error(error.message)
          return
        }

        if (data) setComments((prev) => [...prev, data])
      }

      resetForm()

      onClose()
    },
    [addCommentForm, applicationId, isUpdateForm, onClose, supabase],
  )

  const columns: ColumnDef<Tables<"application_comment">>[] = [
    ...DefaultColumns,
    {
      accessorKey: "action",
      header: "",
      cell: ({ row }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="ghost">
                <MoreVertical className="w-5 h-5" />
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent side="right" align="start">
              <DropdownMenuItem
                onClick={() => {
                  setIsUpdateForm(true)

                  addCommentForm.reset({
                    comment: row.original.comment,
                    id: row.original.id,
                  })

                  onOpen()
                }}
              >
                <Pencil className="mr-2 w-4 h-4" />
                <span>Edit Details</span>
              </DropdownMenuItem>

              <DropdownMenuItem onClick={() => handleDeleteComment(row.original.id)}>
                <Trash className="mr-2 w-4 h-4" />
                <span>Delete Comment</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
  ]

  return (
    <>
      {isLoading ? (
        <Loader2 className="animate-spin w-fit mx-auto" />
      ) : (
        <>
          <div className="flex flex-col items-end gap-4">
            <DataTable
              TopActionButtons={() => <div />}
              EndActionButtons={() => (
                <Button onClick={onOpen}>
                  <Plus className="w-5 h-5" />
                  Add Note
                </Button>
              )}
              columns={columns}
              data={comments}
              hideOtherActions
              hidePagination
            />
          </div>

          <Dialog open={isDialogOpen} onOpenChange={onOpenChange}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add Note</DialogTitle>
              </DialogHeader>

              <Form {...addCommentForm}>
                <form onSubmit={addCommentForm.handleSubmit(handleAddComment)} className="grid gap-4">
                  <InputFormField label="Comment" name="comment" placeholder="text goes here..." required />

                  <DialogFooter className="w-full grid grid-cols-2">
                    <DialogClose type="button" asChild>
                      <Button type="button" variant="white">
                        Close
                      </Button>
                    </DialogClose>

                    <Button type="submit">{isUpdateForm ? "Update" : "Save"}</Button>
                  </DialogFooter>
                </form>
              </Form>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  )
}

export default AdminCommentsPage
