import { Button } from "@/components/ui/button"
import { Loader2 } from "lucide-react"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useAppStore } from "../store"
import { useFromApplication } from "@/src/hooks/UseFromApplication.ts"

const ApplicationSubmittedPage = () => {
  const { isLeadership, canSubmitAfterDeadline } = useAppStore.getState()
  const fromApplication = useFromApplication()

  const [isLoading, setIsLoading] = useState(true)
  const [applicationSubmittedDate, setApplicationSubmittedDate] = useState<string | null>(null)

  useEffect(() => {
    async function fetchApplication() {
      const { data } = await fromApplication.select("submitted_at").maybeSingle()

      if (data) setApplicationSubmittedDate(data.submitted_at)
    }

    fetchApplication().finally(() => setIsLoading(false))
  }, [fromApplication])

  if (isLoading) {
    return (
      <div className="h-screen grid place-content-center">
        <Loader2 className="animate-spin w-10 h-10" />
      </div>
    )
  }

  // if (!applicationSubmittedDate && !isLeadership)
  //   return (
  //     <div>
  //       <Button
  //         onClick={() => {
  //           navigate(`/apply/_/applicants`)
  //         }}
  //       >
  //         Apply to Participate
  //       </Button>
  //     </div>
  //   )

  return (
    <>
      <div>
        <div className="flex flex-col gap-4">
          <div className="space-y-4">
            {applicationSubmittedDate != null && (
              <>
                <h1>Application Submitted</h1>

                <p>Your application was submitted on {new Date(applicationSubmittedDate).toLocaleString()}.</p>
              </>
            )}
            {(isLeadership || canSubmitAfterDeadline) && (
              <Button>
                <Link to={`/apply/_/applicants`}>Edit your application</Link>
              </Button>
            )}
          </div>

          {isLeadership && (
            <div className="space-x-4">
              <Button asChild>
                <Link to={`/casting/list`}>All Applications</Link>
              </Button>

              <Button asChild>
                <Link to={`/casting/family`}>Casts and Volunteers</Link>
              </Button>

              <Button asChild>
                <Link to={`/casting/core`}>Core Cast and Directors</Link>
              </Button>

              <Button asChild>
                <Link to={`/manage/memorabilia`}>Manage Memorabilia</Link>
              </Button>

              <Button asChild>
                <Link to={`/templates`}>Edit Templates</Link>
              </Button>

              <Button asChild>
                <Link to={`/arrive/_/photos`}>Badge Photo</Link>
              </Button>

              <Button asChild>
                <Link to={`/order/_/meals`}>Order Meals</Link>
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ApplicationSubmittedPage
