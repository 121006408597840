export const getAgeInYears = (birthDate: string): number => {
  const bd = new Date(birthDate)
  const pagDate = new Date(2025, 7, 1)
  let ageInYears = pagDate.getFullYear() - bd.getFullYear()
  if (bd.getMonth() > pagDate.getMonth()) {
    ageInYears--
  } else if (bd.getMonth() == pagDate.getMonth() && bd.getDay() > pagDate.getDay()) {
    ageInYears--
  }
  return ageInYears
}

const isAgeBetween = (ageInYears: number, start: number, end: number): boolean => {
  return ageInYears >= start && ageInYears <= end
}

type AgeGroupArgs = {
  whatFor: string
  minAge: number
  maxAge: number
  isFemale: boolean
  peopleNeeded: number
}

type Applicant = {
  female: boolean | null
  birth_date: string | null
}

class AgeGroup {
  whatFor: string
  minAge: number
  maxAge: number
  isFemale: boolean
  numPeopleNeeded: number

  constructor(args: AgeGroupArgs) {
    this.numPeopleNeeded = args.peopleNeeded
    this.whatFor = args.whatFor
    this.minAge = args.minAge
    this.maxAge = args.maxAge
    this.isFemale = args.isFemale
  }

  isInGroup(applicant: Applicant) {
    return (
      this.isFemale === applicant.female && isAgeBetween(getAgeInYears(applicant.birth_date!), this.minAge, this.maxAge)
    )
  }

  key() {
    return `${this.isFemale ? "F" : "M"}${this.minAge}-${this.maxAge}`
  }

  display(numPeopleCurrent: number) {
    return `${this.whatFor} ${this.key()} (${numPeopleCurrent}/${this.numPeopleNeeded})`
  }

  className(numPeopleCurrent: number) {
    const percentDone = numPeopleCurrent / this.numPeopleNeeded
    if (percentDone === 1) {
      return "bg-green-200"
    } else if (percentDone < 0.5) {
      return "bg-red-200"
    } else if (percentDone > 1) {
      return "bg-orange-200"
    } else {
      return "bg-yellow-200"
    }
  }
}

const templeAgeGroup = new AgeGroup({
  whatFor: "Temple",
  minAge: 18,
  maxAge: 64,
  isFemale: false,
  peopleNeeded: 20,
})

const panelsAgeGroup = new AgeGroup({
  whatFor: "Panels",
  minAge: 18,
  maxAge: 64,
  isFemale: true,
  peopleNeeded: 36,
})

const pillarsAgeGroup = new AgeGroup({
  whatFor: "Pillars",
  minAge: 14,
  maxAge: 17,
  isFemale: false,
  peopleNeeded: 14,
})

const flagStickAgeGroup = new AgeGroup({
  whatFor: "Flag/Stick",
  minAge: 8,
  maxAge: 11,
  isFemale: false,
  peopleNeeded: 10,
})

const stickballAgeGroup = new AgeGroup({
  whatFor: "Stickball",
  minAge: 4,
  maxAge: 7,
  isFemale: false,
  peopleNeeded: 5,
})

const jamieAgeGroup = new AgeGroup({
  whatFor: "Jamie",
  minAge: 1,
  maxAge: 3,
  isFemale: false,
  peopleNeeded: 3,
})

export const ageGroups = [
  templeAgeGroup,
  panelsAgeGroup,
  pillarsAgeGroup,
  flagStickAgeGroup,
  stickballAgeGroup,
  jamieAgeGroup,
]

export const otherGroup = "Other"

const getAgeGroupKeyForApplicant = (applicant: Applicant): string => {
  const groups = ageGroups.filter((ag) => ag.isInGroup(applicant))
  if (groups.length > 0) {
    return groups[0].key()
  }
  return otherGroup
}

export const getAgeGroupsForApplication = (applicants: Applicant[]): Record<string, number> => {
  const applicationAgeGroupCounts = Object.fromEntries(ageGroups.map((ag) => [ag.key(), 0]))
  applicationAgeGroupCounts[otherGroup] = 0
  applicants.forEach((applicant) => {
    applicationAgeGroupCounts[getAgeGroupKeyForApplicant(applicant)]++
  })
  return applicationAgeGroupCounts
}
